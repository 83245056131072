<template>
  <q-layout view="lHh Lpr lFf" class="bg-mg-100">
    <q-header class="bg-mg-900">
      <q-toolbar>
        <q-btn
            flat
            dense
            round
            @click="leftDrawerOpen = !leftDrawerOpen"
            aria-label="Menu"
            icon="menu"
        />

        <q-toolbar-title>
          Mono Warehouse
        </q-toolbar-title>

      </q-toolbar>
    </q-header>

    <q-drawer
        :mini="!leftDrawerOpen"
        :breakpoint="1"
        show-if-above
        class="bg-green-950 text-white"
    >
      <q-list>
        <q-item-label header>Essential Links</q-item-label>
        <q-item clickable
                v-for="item in menItems"
                :key="item.title"
                @click="routeTo(item.route)">
          <q-item-section avatar>
            <q-icon :name="item.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ item.title }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable
                @click="logout">
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Chiqish</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container >
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import {useRouter} from "vue-router";

export default {
  name: 'HomeView',

  setup () {
    const router = useRouter()

    const menItems = ref([
      {title: "Sotuv", icon: "sell", route: "/sell"},
      {title: "Maxsulot qo'shish", icon: "add", route: "/add"},
      {title: "Login", icon: "login", route: "/Login"},
      {title: "Barcha maxsulotlar", icon: "inventory_outline", route: "/all"},
      {title: "Mijozlar", icon: "man_2", route: "/clients"},
      {title: "Tashkilotlar", icon: "corporate_fare", route: "/organizations"},
      {title: "Buyurtmalar", icon: "list_alt", route: "/orders"},
      {title: "Kategoriyalarni boshqarsih", icon: "account_tree", route: "/category/manage"},
    ])

    function logout() {
      localStorage.clear()
      window.location.href = "/login"
    }


    function routeTo(path) {
      router.push(path)
    }

    return {
      leftDrawerOpen: ref(false),
      routeTo,
      menItems,
      logout
    }
  }
}
</script>
