import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        children: [

            {
                path: '/add',
                name: 'add',
                component: () => import(/* webpackChunkName: "add" */ '../views/AddProductView.vue')
            },
            {
                path: '/edit/:id',
                name: 'EditProduct',
                component: () => import(/* webpackChunkName: "edit" */ '../views/AddProductView.vue')
            },
            {
                path: '/login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
            },
            {
                path: '/all',
                name: 'all',
                component: () => import(/* webpackChunkName: "all" */ '../views/AllProductsView.vue')
            },
            {
                path: '/about',
                name: 'about',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
            },
            {
                path: '/sell',
                name: 'Sell',
                component: () => import(/* webpackChunkName: "sell" */ '../views/SellView.vue')
            },
            {
                path: '/clients',
                name: 'Clients',
                component: () => import(/* webpackChunkName: "clients" */ '../views/ClientsView.vue')
            },
            {
                path: '/organizations',
                name: 'Organizations',
                component: () => import(/* webpackChunkName: "organizations" */ '../views/OrganizationsView.vue')
            },
            {
                path: '/orders',
                name: 'Orders',
                component: () => import(/* webpackChunkName: "orders" */ '../views/OrdersView.vue')
            },
            {
                path: '/print',
                name: 'Print',
                component: () => import(/* webpackChunkName: "print" */ '../views/PrintView.vue')
            },
            {
                path: '/category/manage',
                name: 'CategoryManage',
                component: () => import(/* webpackChunkName: "category" */ '../views/ManageCategoriesView.vue')
            }
        ]
    },
    {
        path: '/template/:name/:title/:code',
        name: 'template',
        component: () => import(/* webpackChunkName: "template" */ '../components/print/PrintTemplate.vue')
    }
]
// check token before each route
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
