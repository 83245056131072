import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import quasarUserOptions from './quasar-user-options.js'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import './axios.js'
import '@/assets/main.css'
import './style.css'

createApp(App).use(Quasar, quasarUserOptions)
    .use(VueSweetalert2)
    .use(store).use(router).mount('#app')
